.file-chooser{
    margin-block:20px;
}

.file-chooser-input{
    display: flex;
}
.file-chooser-input span{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    display: grid;
    align-items: center; 
    padding-inline: 10px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
}
.file-chooser-input button{
    min-width: fit-content;
    outline: none;
    background-color: transparent;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    border: 1px solid #007bff;
    color:#007bff;
    padding: 6px 30px;
}

 .file-chooser-input .remover{
    color: #dc3545;
    border:1px solid #dc3545

 }

  .file-chooser-input .vazio{
   color: #898989;
   cursor: pointer;

 }