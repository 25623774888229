.linha-cores-intercaladas > td:nth-child(even){
  background-color:  var(--backgroundColorTd); 
}

.linha-cores-intercaladas > th:nth-child(odd){
  background-color: var(--backgroundColorTr);
}

.linha-cores-intercaladas > th:nth-child(even){
  background-color: var(--backgroundColorTd);
}

.linha-cores-intercaladas td{
   border-top: 1px solid #777;
}

.linha-cores-intercaladas:nth-last-child(2) td{
   border-bottom: 1px solid #777;
}

.linha-cores-intercaladas:nth-last-child(1) td{
   border-bottom: 1px solid #777;
}

.linha-cores-intercaladas td:nth-child(1){
  border-left: 1px solid #777;
}

.linha-cores-intercaladas td:nth-last-child(1){
  border-right: 1px solid #777;
}

.linha-cores-intercaladas:hover td{
  border-top:1px solid #333;
  border-bottom: 1px solid #333;
}

.linha-cores-intercaladas:hover td:nth-child(1){
  border-left: 1px solid #333;
}

.linha-cores-intercaladas:hover td:nth-last-child(1){
  border-right: 1px solid #333;
}
