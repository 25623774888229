
.displayPDF input,
.displayPDF button {
	font: inherit;
}

.displayPDF header {
	position: sticky;
	position: -webkit-sticky;
	top: 47px; /* required */
	z-index: 2;
	display: flex;
	background-color: #323639;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
	color: white;
	padding: 0;
	margin: 0;
	width: 100%;
	justify-content: space-between;
	cursor: default;
}

.displayPDF header .closePDF,
.displayPDF header .buttonsPDF {
	margin: 0;
	padding: 20px;
	font-size: 1.3rem;
}

.displayPDF header .buttonsPDF {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	min-width: 300px;
}
.displayPDF header div i {
	cursor: pointer;
}
.displayPDF__container {
	display: flex;
	justify-content: center;
	overflow: hidden;
	max-width: 100vw;
}

.displayPDF__container__document::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.displayPDF__container__document {
	overflow: auto; /* Permite rolagem */
	max-width: 100%; /* Limita a largura máxima */
}

.displayPDF__container__document .react-pdf__Document {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.displayPDF__container__document .react-pdf__Page {
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.displayPDF__container__document .react-pdf__Page canvas {
	/* max-width: 80vw; */
	height: auto !important;
}

@media print {
	/* @page {
    size: auto;
  } */
	html,
	body {
		margin: 0;
		padding: 0;
	}

	.displayPDF__container__document {
		width: 99.7% !important;
		padding: 0 !important;
		margin: 0 !important;
		page-break-before: avoid;
		page-break-after: avoid;
		overflow: hidden;
	}
	.displayPDF__container__document .react-pdf__Document,
	.displayPDF__container__document .react-pdf__Page,
	.displayPDF__container__document .react-pdf__Page canvas {
		width: 100% !important;
		max-width: 100% !important;
		padding: 0 !important;
		margin: 0 !important;
		page-break-before: avoid;
		page-break-after: avoid;
		overflow: hidden;
	}
}
