.semi-circle-progress-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .semi-circle-progress-container svg {
    transform: rotate(-200deg); /* Ajuste inicial */
  }

  .semi-circle-progress-container svg circle {
    fill: none;
    stroke-width: 7;
  }
    
  .semi-circle-progress-container .progress-text {
    width: 100%;
    position: absolute;
    padding-top: 18px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  .semi-circle-progress-container .progress-text:hover {
    color:#fff !important
}; 
  