.install-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    z-index: 1000;
    display: flex;
    gap:10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.install-banner p {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.install-banner button.btn-install-ok {
    background-color: #f62222;
    color: white;
    padding: 8px 12px;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 200px;
}

.install-banner button.btn-install-cancel {
    background-color: #5a5a5a;
    color: white;
    padding: 8px 12px;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 200px;
}

.install-banner button:hover {
    background-color: #c92323;
}